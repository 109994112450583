import { Loading } from 'publicViews';
import Loadable from 'react-loadable';
import Ability from '../../ability';
import WebApp from '../../webapp';
import RPC from 'api';

const load = (name) => {
    return Loadable({
        loader: () => import(`./${name}/index.js`),
        loading: Loading
    });
};

const loadOutPage = (name) => {
    return Loadable({
        loader: () => import(`../${name}/index.js`),
        loading: Loading
    });
};

const loadIvsScheme = () => {
    if (WebApp.SupportIntelliScheme) {
        if (WebApp.DeviceType.indexOf('SD') > -1) {//球机灵珑项目，智能的第一张页面显示为IVSMode
            return load('IVSMode');
        } else {//IPC分时页面
            return load('IVSModePre');
        }
    } else {//IPC智能显示的第一张页面是“智能方案”页面
        if (WebApp.DeviceType.indexOf('SD') > -1 && WebApp.CHANNEL_NUMBER === 1) {
            return load('SingleSDIExclusionConfig');
        } else {
            if (WebApp.DeviceType.indexOf('TPC') > -1 && WebApp.DeviceType.indexOf('SD') > -1) {
                return load('IExclusionConfigTPCSD');
            } else {
                return load('IExclusionConfigNew');
            }
        }
    }
};

const AIPageMenuNew = [
    {
        key: 'IVS',
        iconType: 'ivs',
        text: 'ivs.IVS',
        authority: 'AuthEventCfg',
        condition: (resolve) => {
            resolve(true);
        },
        subMenu: [
            {
                text: 'ivs.IntelliResourceAllocation',
                key: 'IntelliResourceAllocation',
                component: load('IntelliResourceAllocation'),
                condition: (resolve) => {
                    Promise.all([RPC.intelli.getCaps({capsName : 'IntelliLimitGroup'})]).then(([result]) => { //获取是否支持智能资源分配方案
                        if (result && result.caps && result.caps.IntelliLimitGroup && result.caps.IntelliLimitGroup.Support) {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    }).catch(() => {
                        resolve(false);
                    });
                },
            },
            {
                text: 'ivs.IvsScheme',
                key: 'IvsScheme',
                component: loadIvsScheme(),
                condition: (resolve) => {
                    resolve(true);
                },
            },
        ]
    },
    {
        key: 'IvsAudioConfig',
        iconType: 'abnormalAudio',
        text: 'ivs.IvsAudioConfig',
        authority: 'AuthEventCfg',
        condition: (resolve) => {
            RPC.devAudioDetectManager('getCaps').then(({caps}) => {
                resolve(caps && caps.SmartSoundDetect && caps.SmartSoundDetect.Support);
            }).catch((e) => {
                console.log(e,'error caps');
                resolve(false);
            });
        },
        subMenu:[
            {
                text:'ivs.SmartSoundDetect',
                key:'AbnormalAudio',
                component:load('AbnormalAudio'),
                condition : function (resolve) {
                    resolve(true);
                }
            }
        ]
    },
    {
        key: 'CalibrateMatrixConfig',
        iconType: 'storage',
        text: 'ivs.WideViewLink', //全景联动
        authority: 'AuthEventCfg',
        condition: (resolve) => {
            if (WebApp.IsSpecialBrowse) {
                resolve(false);
            }
            Promise.all([RPC.MasterSlaveGroup.getCaps()]).then(([caps]) => {
                if (caps && caps.MasterSlaveTrack && (caps.MasterSlaveTrack.Support || !caps.MasterSlaveTrack.SupportMode || caps.MasterSlaveTrack.SupportMode !== 'Indepent')) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch(() => {
                resolve(false);
            });
        },
        component: loadOutPage('CalibrateMatrixConfig')
    },
    {
        key: 'RadarLink',
        iconType: 'qos',
        text: 'ivs.RadarLink', //雷达联动
        authority: 'AuthEventCfg',
        condition: (resolve) => {
            resolve(false);
            // if (WebApp.radarType && WebApp.radarType !== 'FuseRadar') {
            //     resolve(true);
            // } else {
            //     resolve(false);
            // }
        },
        component: loadOutPage('RadarLink')
    },
    {
        key: 'RadarPara',
        iconType: 'qos',
        text: 'RadarPara', //雷达配置
        // authority: 'AuthEventCfg',
        condition: (resolve) => {
            // resolve(true);
            if (WebApp.radarType && WebApp.radarType !== 'FuseRadar') {
                resolve(true);
            } else {
                resolve(false);
            }
        },
        subMenu: [
            {
                text: 'per.RadarSetting',//雷达设置
                key: 'RadarSet',
                component: loadOutPage('RadarSet'),
                condition: (resolve) => {
                    if (WebApp.radarType && WebApp.radarType !== 'FuseRadar') {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                },
            },
            {
                text: 'per.LinkageGuide',//联动向导
                key: 'LinkageGuide',
                component: loadOutPage('RadarLinkageGuide'),
                condition: (resolve) => {
                    if (WebApp.radarType && WebApp.radarType !== 'FuseRadar') {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                },
            },
        ]
    },
    {
        key: 'IntelliTour',
        iconType: 'intellitour',
        text: WebApp.DeviceType.indexOf('IPC') !== -1 ? 'ivs.IntelliPlan' : 'ivs.TourPlan',
        authority: 'AuthEventCfg',
        condition: (resolve) => {
            let supportIntelliTour = false;
            let requestArr = [RPC.intelliScheme.getCaps(), RPC.intelli.getCaps({
                capsName: 'SupportIntelliScheme'
            })];
            Promise.all(requestArr.map(p => p.catch(e => e))).then(json => {
                let [caps1, caps2] = json;
                //SD
                if (caps1 && caps1.Caps && caps1.Caps.SchemeTour && caps1.Caps.SchemeTour.Support) {
                    supportIntelliTour = true;
                    resolve(true);
                }
                //IPC
                if (WebApp.DeviceType.indexOf('SD') === -1 && caps2 && caps2.caps && caps2.caps.SupportIntelliScheme) {
                    resolve(true);
                }
            }).finally(() => {
                if (!supportIntelliTour) {
                    Promise.all([Ability.get('PtzCaps'), Ability.get('DSPConflict')]).then(([caps, isConflict]) => {
                        if (!isConflict && caps.IntelliTour && caps.IntelliTour.Support) {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    });
                } else {
                    resolve(false);
                }
            });
        },
        component: load('IntelliTour')
    }
];

export { AIPageMenuNew };
